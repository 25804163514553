import { useState, useRef } from 'react';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import {
   Box,
   Button,
   Flex,
   FormControl,
   FormHelperText,
   FormLabel,
   Heading,
   Input,
   InputGroup,
   InputProps,
   InputRightElement,
   Link,
   VStack,
} from '@chakra-ui/react';
import { FaIcon } from '@ifixit/icons';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { _js } from '@ifixit/localize';
import IFixitLogo from 'Shared/icons/logos/ifixit.svg';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Recaptcha } from 'Shared/recaptcha';
import { Auth } from 'Shared/auth';
import { fireGlobal, goToLastPage } from 'Shared/utils';
import { StatusPanel } from 'Shared/status_panel';
import { FormError } from '../FormError';
import { SSOLoginButtons } from '../SSOLoginButtons';

type LoginFormValues = {
   email: string;
   password: string;
};

function LogInPasswordInput(props: InputProps) {
   const [show, setShow] = useState(false);
   const handleClick = () => setShow(!show);

   return (
      <InputGroup>
         <Input pr={5} type={show ? 'text' : 'password'} {...props} />
         <InputRightElement boxSize="36px">
            <Button
               variant="unstyled"
               onClick={handleClick}
               display="inline-flex"
               alignItems="center"
            >
               <FaIcon icon={show ? faEyeSlash : faEye} boxSize="16px" color="gray.400" />
            </Button>
         </InputRightElement>
      </InputGroup>
   );
}

export const getErrorMessage = (response: any) => {
   switch (response.reason) {
      case 'email': {
         return App.loginMessages.invalid_email;
      }
      case 'password': {
         return App.loginMessages.invalid_password;
      }
      case 'banned': {
         return App.loginMessages.banned;
      }
      case 'captcha': {
         return App.loginMessages.captcha;
      }
      case 'tooManyFailures': {
         const expiration = parseInt(response.lockedOut, 10);
         const xMessage =
            expiration == 1
               ? _js('Click "Forgot?" or wait one minute to try again.')
               : _js('Click "Forgot?" or wait %1 minutes to try again.');
         const message = App.loginMessages.tooManyFailures + ' ' + xMessage;

         return message.replace('%1', expiration.toString());
      }
      case 'tooManySuccesses': {
         const expiration = parseInt(response.lockedOut, 10);
         const xMessage =
            expiration == 1
               ? _js('Wait one minute to try again.')
               : _js('Wait %1 minutes to try again.');
         const message = App.loginMessages.tooManySuccesses + ' ' + xMessage;
         return message.replace('%1', expiration.toString());
      }
      case 'disabledUser': {
         return App.loginMessages.disabledUser;
      }
      case 'insecurePassword': {
         return App.loginMessages.insecurePassword;
      }
      case 'facebook': {
         return _js(
            'Please login using the "Sign in with Facebook" button instead, or remove your Facebook connection by resetting your password.'
         );
      }
      case 'OAuth2_GOOGLE': {
         return _js(
            'Please login using the "Sign in with Google" button instead, or remove your Google connection by resetting your password.'
         );
      }
      case 'adminOnlyEntrypoint': {
         return App.loginMessages.adminOnlyEntrypoint;
      }
      default: {
         return response.reason;
      }
   }
};

export const LogIn = () => {
   const initialValues: LoginFormValues = {
      email: '',
      password: '',
   };

   const recaptchaPlaceholderRef = useRef<HTMLDivElement>(null);

   const handleSubmit = (
      values: LoginFormValues,
      { setSubmitting, setFieldError }: FormikHelpers<LoginFormValues>
   ) => {
      const loginHandler = (response: any) => {
         (Auth as any).clearCookies();
         (StatusPanel as any).deactivate(/* noDelay */ true);

         if (response.loggedIn) {
            fireGlobal('login.success', response);
         } else {
            setFieldError('email', getErrorMessage(response));
            fireGlobal('login.failure', response);
         }
      };

      const loginPromise = Recaptcha.executeInvisible(
         recaptchaPlaceholderRef.current,
         App.recaptchaSiteKey,
         'login'
      )
         .then((recaptchaResponse: any) =>
            (Auth as any).sendLogin({
               login: values.email,
               password: values.password,
               captchaResult: recaptchaResponse,
               ajax: true,
               handler: loginHandler,
            })
         )
         .then((result: any) => {
            setSubmitting(false);
            if (!result.loggedIn) {
               throw undefined;
            }
            goToLastPage();
         });

      LoadingIndicator.withPromise(loginPromise, {
         pendingMessage: _js('Checking your email and password...'),
         successMessage: _js('Login successful'),
         failureMessage: _js('Login unsuccessful'),
      });
   };

   const [ssoError, setSsoError] = useState<string | null>(null);
   const ssoLoginHandler = (response: any) => {
      (Auth as any).clearCookies();
      (StatusPanel as any).deactivate(/* noDelay */ true);

      if (response.loggedIn) {
         fireGlobal('login.success', response);
         goToLastPage();
      } else {
         setSsoError(getErrorMessage(response));
         fireGlobal('login.failure', response);
      }
   };

   return (
      <Box fontSize="16px" width={{ base: '100%', sm: '384px' }}>
         <VStack spacing={6} marginBottom={8}>
            <IFixitLogo />
            <Heading
               as="h2"
               fontSize={{ base: '24px', sm: '30px' }}
               fontWeight="medium"
               border="none"
               textAlign="center"
               lineHeight="normal"
            >
               {_js('Sign in to your account')}
            </Heading>
         </VStack>
         {App.showJoinLink && (
            <Flex justify="center" marginBottom={4}>
               <Box as="span">{_js('New?')}</Box>
               &nbsp;
               <Link href="/Join" color="brand.500" fontWeight="medium">
                  {_js('Join us')}
               </Link>
               .
            </Flex>
         )}
         <VStack
            className="sign-up-wrapper"
            flexDirection="column"
            spacing={6}
            paddingY={8}
            paddingX={6}
            bgColor="white"
            borderWidth={1}
            borderRadius="base"
         >
            <SSOLoginButtons
               loginHandler={ssoLoginHandler}
               error={ssoError}
               setError={setSsoError}
            />
            <Box className="sign-up-form" width="100%">
               <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  <Form>
                     <VStack
                        spacing={6}
                        sx={{ '& > div': { width: '100%' }, '& label': { fontWeight: 'medium' } }}
                     >
                        <Field
                           required
                           name="email"
                           type="email"
                           autoComplete="section-login username"
                           placeholder="albert@domain.com"
                        >
                           {({ field, form }: FieldProps) => (
                              <FormControl isInvalid={Boolean(form.errors.email)}>
                                 <FormLabel>{_js('Email')}</FormLabel>
                                 <Input
                                    id="email"
                                    isInvalid={Boolean(form.errors.email)}
                                    {...field}
                                 />
                                 <FormError id="emailError">{form.errors.email as any}</FormError>
                              </FormControl>
                           )}
                        </Field>
                        <Field
                           required
                           autoComplete="section-login current-password"
                           name="password"
                           minLength={10}
                        >
                           {({ field, form }: FieldProps) => (
                              <FormControl isInvalid={Boolean(form.errors.password)}>
                                 <FormLabel>{_js('Password')}</FormLabel>
                                 <LogInPasswordInput
                                    id="password"
                                    isInvalid={Boolean(form.errors.password)}
                                    {...field}
                                 />
                                 <FormHelperText fontSize="md">
                                    <Link href="/login/forgot_password" color="brand.500">
                                       {_js('Forgot your password?')}
                                    </Link>
                                 </FormHelperText>
                                 <FormError>{form.errors.password as any}</FormError>
                              </FormControl>
                           )}
                        </Field>
                        {App.recaptchaSiteKey && (
                           <Box
                              ref={recaptchaPlaceholderRef}
                              className="showLogin js-captcha-container captcha-container"
                           ></Box>
                        )}
                        <Button id="loginBtn" variant="cta" width="full" type="submit">
                           {_js('Log In')}
                        </Button>
                     </VStack>
                  </Form>
               </Formik>
            </Box>
         </VStack>
      </Box>
   );
};

initializeChakraComponent('LogIn', LogIn);
